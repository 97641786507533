<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="border-radius-xl card-shadow">
            <div class="px-4 py-4 mt-2">
              <v-card class="border-radius-xl">
                <v-row>
                  <v-col lg="6">
                    <div class="mb-10">
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">
                        Цаxим контентийн сан
                      </h5>
                      <p class="text-sm text-body mb-0">
                        Багш боловсруулсан цахим контент, нээлттэй эх материал
                        болон бусад нөөц ашиглан бэлтгэсэн файл, зураг, аудио,
                        видеог байршуулах боломжтой байх;
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-card-text class="px-0 py-0">
                  <v-card  >
                    <v-card-title>
                      <v-row>
                        <v-btn
                          @click="_addTopic"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          class="
                            font-weight-bold
                            text-capitalize
                            ms-auto
                            btn-primary
                            bg-gradient-primary
                            py-3
                            px-6
                            ms-3
                          "
                          >Контент+</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-col cols="6" lg="6" md="6" sm="6">
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Xайx"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text class="px-0 py-0">
                      <v-data-table
                        v-if="topics"
                        :headers="headerNames"
                        :items="topics"
                        :search="search"
                        hide-details
                        hide-default-footer
                        :items-per-page="-1"
                      >
                        <template slot="item" slot-scope="props">
                          <tr @click="showAlert(props.item)">
                            <td>{{ props.item.index }}</td>
                            <td>
                              <v-avatar rounded :size="36" class="my-0 me-5">
                                <v-img
                                  :alt="props.item.avatar"
                                  :src="props.item.avatar"
                                  class="border-radius-lg"
                                ></v-img>
                              </v-avatar>
                            </td>
                            <td>
                              {{ props.item.title }}
                            </td>
                            <td
                              :class="{
                                'border-bottom': i != topics.length - 1,
                              }"
                              class="text-center"
                            >
                              <small
                                style="cursor: pointer"
                                @click.stop="_detail(props.item.videoUrl)"
                                small
                                class="
                                  border-radius-sm
                                  text-xxs text-white
                                  me-1
                                  shadow-none
                                  font-weight-bold
                                  px-3
                                  py-1
                                  btn-warning
                                  bg-gradient-warning
                                "
                                elevation="0"
                                :ripple="false"
                                v-if="
                                  props.item.videoUrl &&
                                  isYoutube(props.item.videoUrl)
                                "
                                title="Контентоо шалгаx"
                                >video</small
                              >

                              <small
                                style="
                                  cursor: pointer;
                                  background: #eef4fa;
                                  color: #007aff;
                                "
                                @click.stop="detail(props.item.slideUrl)"
                                small
                                class="
                                  border-radius-sm
                                  text-xxs
                                  me-1
                                  shadow-none
                                  font-weight-bold
                                  px-3
                                  py-1
                                "
                                elevation="0"
                                :ripple="false"
                                v-if="props.item.slideUrl"
                                title="Контентоо шалгаx"
                                >лекц</small
                              >
                            </td>
                            <td>
                              <v-icon
                                small
                                class="ml-10"
                                @click.stop="_deleteTopic(props.item)"
                              >
                                mdi-delete
                              </v-icon>
                              <v-icon
                                small
                                class="ml-2"
                                @click.stop="_clickRow(props.item)"
                              >
                                mdi-pencil
                              </v-icon>
                            </td>
                          </tr>
                        </template>

                        <v-alert
                          slot="no-results"
                          :value="true"
                          color="error"
                          icon="warning"
                        >
                          Your search for "{{ search }}" found no results.
                        </v-alert>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="addNewItem" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Контент үүсгэx</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.title"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Сэдвийн нэр"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.videoUrl"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Youtube линк"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.slideUrl"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Слайд линк"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Тайлбар"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-between">
          <v-btn
            v-if="editedIndex > -1"
            @click="_deleteTopic"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-danger
              bg-gradient-danger
              py-3
              px-6
            "
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_close"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_save"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSlide">
      <!-- <iframe
        width="100%"
        height="800"
        v-if="
          slideId != null && slideId != undefined
        "
        v-bind:src="slideId"
        frameborder="0"
        allowfullscreen
      ></iframe> -->

      <div id="iframecontainer" v-html="slideId"></div>
    </v-dialog>
    <v-dialog v-model="showVideo">
      <iframe
        width="100%"
        height="800"
        v-if="
          videoId != null &&
          videoId != '' &&
          videoId.toLowerCase().indexOf('youtube') != -1
        "
        v-bind:src="getYoutubeIframe(videoId)"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="_closeDeleteDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_deleteItemConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import myfunction from "@/components/global/myfunction.js";
import { sync } from "vuex-pathify";
export default {
  name: "light-table-transparent",
  data() {
    return {
      search: "",
      path: "contentdatabase",
      headerNames: null,
      dialogDelete: false,
      topics: null,
      editedIndex: -1,
      editedItem: {
        title: null,
        description: null,
        url: null,
      },
      addNewItem: false,
      videoId: null,
      showVideo: false,

      showSlide: null,
      slideId: false,
    };
  },
  mixins: [myfunction],
  computed: {
    ...sync("*"),
  },

  watch: {
    showVideo(val) {
      if (val == false) this.videoId = null;
    },
  },
  created() {
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
        fixed: true,
      },
    ];
    fb.db
      .collection(this.path)
      .orderBy("createdAt", "asc")
      .onSnapshot((docs) => {
        this.topics = [];
        var counter = 0;
        docs.forEach((doc) => {
          let topic = doc.data();
          topic.id = doc.id;
          topic.ref = doc.ref;
          topic.index = ++counter;
          this.topics.push(topic);
        });
      });
  },
  methods: {
    _makeOpen(item) {
      if (item.public) item.ref.update({ public: !item.public });
      else item.ref.update({ public: true });
    },
    isYoutube(videoUrl) {
      return videoUrl.includes("youtube");
    },

    _clickRow(item) {
      this.editedIndex = this.topics.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.addNewItem = true;
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      }
      return null;
    },
    _addTopic() {
      this.addNewItem = true;
    },
    _close() {
      this.addNewItem = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    _save() {
      if (this.editedIndex > -1) {
        this.editedItem.ref.update(this.editedItem).then(() => {
          this._close();
        });
      } else {
        this.editedItem.createdAt = new Date();
        this.editedItem.public = false;
        fb.db
          .collection(this.path)
          .doc()
          .set(this.editedItem, { merge: true })
          .then(() => {
            this._close();
          });
      }
    },
    _detail(url) {
      this.addNewItem = false;
      this.videoId = url;
      this.showVideo = !this.showVideo;
    },
    detail(url) {
      this.addNewItem = false;
      this.slideId = url;
      this.showSlide = !this.showSlide;
    },
    _closeDeleteDialog() {
      this.dialogDelete = false;
    },
    _deleteTopic(item) {
      // this.editedIndex = this.topics.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _deleteItemConfirm() {
      this.editedItem.ref.delete().then(() => {
        this.dialogDelete = false;
        this._close();
      });
    },
  },
};
</script>
<style>
#iframecontainer iframe {
  width: 100% !important;
}
</style>
 